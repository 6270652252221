.login-box {
    padding: 25px;
    background-color: #ccc;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.chat-input {
    background-color: #ccc;
    border-radius: 10px;
    padding: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
    border: 2px solid black;
}

.chat-message {
    background-color: #ccc;
    border-radius: 10px;
    padding: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
}

/* Pagination */
.navigationButtons {
	width: 80%;
	height: 40px;
	list-style: none;
	display: flex;
	justify-content: center;
	margin: auto;
	padding-top: 40px;
	margin-bottom: 80px;
}
	
.navigationButtons a {
	padding: 10px;
	margin: 8px;
	border-radius: 5px;
	border: 1px solid black;
	color: black;
	cursor: pointer;
}
	
.navigationButtons a:hover {
	color: white;
	background-color: #444;
}
	
.navigationActive a {
	color: white;
	background-color: #444;
}
	
.navigationDisabled a {
	color: white;
	background-color: grey;
}
